<template>
  <div>
      兑换规则
  </div>
</template>

<script>
export default {
  name: 'ExchangePage',
  data() {
    return {
      driver: null
    }
  },
  created() {

  },
  methods: {
    guide() {

    }
  }
}
</script>